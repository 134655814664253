export const links = [

    {
        "nombre": "Agenda",
        "link": "/Agenda",
    },
 

    {
       "nombre" : "Transparencia",
       "link" : "/Transparencia",
        "submenu" : [
            {
                "subMenuNombre" : "48 Rubros",
                "subMenuUrl" : "/Transparencia/Rubros"
            },
            {
                "subMenuNombre" : "Rubros Anteriores",
                "subMenuUrl" : "/Transparencia/Rubros/RubrosHistoricos"
            },

            {
                "subMenuNombre" : "Otra Información",
                "subMenuUrl" : "/Transparencia/Rubros/Informacion"
            }
        ]
    },
    {
        "nombre" : "Tramites",
        "link" : "/Tramites",
    },
    {
        "nombre" : "Programas",
        "link" : "/Programas",
    }
]