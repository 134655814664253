import React, { useEffect, useState } from 'react';
import {useParams} from 'react-router-dom';
import { armonizacionArray } from '../../json/docsArray';

const ArmonizacionDetalle = ({array, id}) => {
    //const [data, setData] = useState([]);
    const url = useParams();
    const data = armonizacionArray.find(obj => obj.nombre===url.children)
    console.log(data)
    useEffect(() => {
        window.scrollTo(0, 0);
        console.log(url.children)
        //setData(array)
    }, []);

    return (
        <div className="container pt-5">
            <div className="row">
                <div className="col-12">
                    <h3 className='title'>{data.nombre}</h3>
                    <hr className='hr-gob'/>
                </div>
                <div className="col-12">
                    {
                        data.anio.map((item, i) => (
                            <div key={i}>
                                <h6>{item.number}</h6>
                                {
                                    item.datos.map((doc, n) =>(
                                        <div key={n}>
                                            <p>{doc.nombre}</p>
                                            <div>
                                                <a href={doc.pdf} target="_blank"><span className="badge bg-primary">PDF</span></a>
                                                <a href={doc.xlsx} target="_blank"><span className="badge bg-success">XLSX</span></a>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default ArmonizacionDetalle;