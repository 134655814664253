import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { tramites_item } from '../../json/tramitesItem';
import './Tramites.css';

class Tramites extends Component {
    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <br /><br />
                        <h2>Trámites y Servicios</h2>
                        <hr className="hr-gob" />
                    </div>
                </div>

                <div className="accordion" id="tramitesAccordion">
                    {
                        tramites_item.map((item, index) => (
                            <div key={index} className="accordion-item">
                                <h2 className="accordion-header" id={`heading${index}`}>
                                    <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target={`#collapse${index}`}
                                        aria-expanded="false"
                                        aria-controls={`collapse${index}`}
                                    >
                                        {item.nombre}
                                    </button>
                                </h2>
                                <div
                                    id={`collapse${index}`}
                                    className="accordion-collapse collapse"
                                    aria-labelledby={`heading${index}`}
                                    data-bs-parent="#tramitesAccordion"
                                >
                                    <div className="accordion-body">
                                        {item.descripcion}
                                        <br />
                                        <Link target="_blank" to={item.tramite}>
                                            Ver más detalles
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        );
    }
}

export default Tramites;
