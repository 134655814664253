export const ultimasNoticias = [
    {
        "imagen": "../assets/imgs/AgendaAmbiental2024.jpg",
        "url": "/pdf/AgendaAmbiental2024.pdf",
        "titulo" : "Agenda Ambiental 2024",
        "fecha": "Conoce nuestras conmemoraciones, aniversaiors y algunas otras fechas relevantes"
    },
    {

        "imagen": "../assets/imgs/Ozono2024.jpg",
        "url": "/pdf/Ozono2024.pdf",
        "titulo": "Temporada de ozono 2024",
        "fecha": "Invitamos a las y los hidalguenses a adoptar medidas que mitiguen la emisión de contaminantes a la atmósfera, ante la actual Temporada de Ozono."
    },
    {
        "imagen": "../assets/imgs/inventario_EEGyCEI_Hgo.jpg",
        "url": "/pdf/inventario_ EEGyCEI_Hgo.pdf",
        "titulo": "Inventario Estatal de Emisiones",
        "fecha" : ""
    },
    {
        "imagen": "../assets/imgs/temporada_de_particulas.jpg",
        "url": "/pdf/temporada_de_particulas.pdf",
        "titulo": "Temporada de partículas 2024",
        "fecha": "Todo lo que tienes que saber sobre la temporada seca-fría que se caracteriza por inversiones térmicas y mayor presencia de partículas suspendidas en el aire, o también llamada Temporada de partículas."
    },
    {
        "imagen": "../assets/imgs/reforestacion_epocas_invernales.jpg",
        "url": "/imgs/reforestacion_epocas_invernales1.pdf",
        "titulo": "Recomendaciones para reforestar en épocas invernales.",
        "fecha": "Consulta esta guía que reúne algunas recomendaciones a contemplar si quieres reforestar durante la época invernal."
    }

]