import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './art70.css';
import { art70item } from '../../json/art70item';

const Art70 = () => {
    return (
        <div className='container'>
            <div className='row'>
                <div className='art70-container'>
                    <h5 className='title'>Articulo 70</h5>
                    <hr className='hr-gob' />
                </div>
                {art70item.map((item, index) => (
                    <div key={index} className='col-md-3 col-sm-6 col-12'>
                        <div className='detalle-container_art70'>
                            <div className='detalle_art70'>
                                <a href="/Transparencia/xlsx/a70_f01_f4Semarnath.xlsx" target="_blank" rel="noopener noreferrer">XLSX</a>
                                <a href="/Transparencia/htm/a70_f01_f4Semarnath.htm" target="_blank" rel="noopener noreferrer">Ver Tabla</a>
                                <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Art70;