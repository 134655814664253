import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './transparencia.css';
import { transparencia_item } from '../../json/transparenciaItem';

const Transparencia = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container transparencia-container">
      <div className="row">
        <div className="col-12">
          <div className="transparencia_title text-center">
            <h1>Transparencia</h1>
          </div>
        </div>
      </div>
      <div className="row">
        {transparencia_item.map((item, index) => (
          <div key={index} className="col-md-4 col-sm-6 col-12 mb-4">
            <div className="card transparencia-card shadow-sm">
              <div className="card-body">
                <h5 className="card-title">{item.transparencia_item_nombre}</h5>
                <p className="card-text">
                  {item.transparencia_item_descripcion }
                </p>
                <Link
                  to={item.transparencia_item_url}
                  className="btn btn-primary"
                >
                  Ver más
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Transparencia;