import { BrowserRouter, Routes, Route, Switch } from "react-router-dom";
import Inicio from './components/Inicio/Inicio';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Transparencia from './components/Transparencia/Transparencia';
import Rubros from './components/Rubros/Rubros';
import DetalleRubro from './components/DetalleRubro/DetalleRubro';
import RubrosHistoricos from "./components/RubrosHistoricos/RubrosHistoricos";
import Agenda from "./components/Agenda/Agenda";
import Error404 from "./components/Error404/Error404";
import Tramites from "./components/Tramites/Tramites";
import Informacion from "./components/Informacion/Informacion";
import Programas from "./components/Programas/Programas";
import Armonizacion from './components/Armonizacion/Armonizacion';
import ArmonizacionDetalle from './components/Armonizacion/ArmonizacionDetalle';
import OtrosProgramas from './components/OtrosProgramas/OtrosProgramas';
import Art70 from './components/Art70/Art70';
import Ayudas from './components/Ayudas/Ayudas';
import Adquisiciones from './components/Adquisiciones/Adquisiciones';




function App() {
  return (
    <BrowserRouter>
      <Header/>
        <Routes>
          <Route exact path="/" element={ <Inicio /> } />
          <Route exact path="/Transparencia" element={ <Transparencia/> } />
          <Route exact path="/Transparencia/Rubros" element={ <Rubros/> } />
          <Route exact path="/Transparencia/Rubros/DetalleRubro/:nombre" element={ <DetalleRubro/> } />
          <Route exact path="/Transparencia/Rubros/RubrosHistoricos" element={<RubrosHistoricos />} />
          <Route exact path="/Transparencia/Rubros/Informacion" element={<Informacion />} />
          <Route exact path="/Transparencia/Rubros/Art70" element={<Art70 />} />
          <Route exact path="/Transparencia/RubrosHistoricos" element={<RubrosHistoricos />} />
          <Route exact path="/Transparencia/:children" element={<Transparencia />} />
          <Route exact path="/OtrosProgramas" element={<OtrosProgramas />} />
          <Route exact path="/Agenda" element={ <Agenda/> } />
          <Route exact path="/Armonizacion" element={ <Armonizacion/> } />
          <Route exact path="/Armonizacion/DetalleArmonizacion/:children" element={ <ArmonizacionDetalle/> } />
          <Route exact path="*" element={<Error404 />} />
          <Route exact path="/Tramites" element={<Tramites />} />
          <Route exact path="/Informacion" element={<Informacion />} />
          <Route exact path="/Programas" element={<Programas />} />
          <Route exact path="/Programas/OtrosProgramas" element={<OtrosProgramas />} />
          <Route exact path="/OtrosProgramas" element={<OtrosProgramas />} />
          <Route exact path="/Ayudas/Ayudas" element={<Ayudas/>} />
          <Route exact path="Transparencia/Rubros/Informacion/Ayudas" element={<Ayudas />} />
          <Route exact path="Transparencia/Rubros/Informacion/Adquisiciones" element={<Adquisiciones />} />
         
        </Routes>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;
