import React, { useEffect } from 'react';
import { armonizacionArray } from '../../json/docsArray';
import CardDocuments from './CardDocuments';

const Armonizacion = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
	        <div className="container pt-5">
	        	<div className="row">
	        		<div className="col-12">
	            		Armonización Contable
	        		</div>
	            </div>
	        </div>
	        <CardDocuments array={armonizacionArray} id={'arc'}/>
        </>
    )
}

export default Armonizacion;
