import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { informacionArray } from '../../json/informacion';
import './Informacion.css';

class Informacion extends Component {
    render() {
        return (
            <div className="container informacion-container">
                <div className="row">
                    <div className="col_12">
                        <div className="informacion.title">
                            <h1>Otra Información</h1>
                            <hr className='hr-gob' />
                        </div>
                    </div>
                </div>

                <div className="card_container_informacion">
                    <div className='row'>
                        {informacionArray.map((item, index) => (
                            <div key={index} className='card_informacion'>
                                <div className='card_nombre'>
                                    <h5>{item.nombre}</h5>
                                    <span className="badge bg-secondary">
                                        {item.pdf ? (
                                            // PDF
                                            <a href={item.pdf} target="_blank" rel="noopener noreferrer">
                                                Ver PDF
                                            </a>
                                        ) : (
                                                // Ruta Interna

                                            <Link to={item.route}>Ir a la información</Link>
                                        )}
                                    </span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

export default Informacion;
