export const agenda = [

    { id: 0, title: 'Llevó a cabo la firma de actas de entrega-recepción de la SEMARNATH. Recibió la secretaria Mónica Patricia Mixtega Trejo y entregó el titular saliente, Said Javier Estrella García', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1567205078372651008?s=20', start: '2022-09-06', color: 'var(--wine)',},
    { id: 0, title: 'Realizó un recorrido por las instalaciones de la SEMARNATH para conocer las diferentes áreas que integran esta dependencia y al equipo de trabajo', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1567298344690622464?s=20', start: '2022-09-06', color: 'var(--wine)',},
    { id: 0, title: 'Acudió, en representación del titular del Ejecutivo, al segundo Informe De Gobierno del presidente municipal de Zimapán, Alan Rivera.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1569734133357723650?s=20', start: '2022-09-13', color: 'var(--wine)', },
    { id: 0, title: 'Asistió el día de ayer, al segundo Informe De Gobierno de la presidenta municipal de Jacala Magdalena Rubio Pérez.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1569676096550977537?s=20', start: '2022-09-13', color: 'var(--wine)', },
    { id: 0, title: 'Acompañó al Gobernador Julio Menchaca al tradicional Grito de Independencia para celebrar y honrar a los héroes que nos dieron patria y libertad.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1570895464333189122?s=20', start: '2022-09-16', color: 'var(--wine)', },
    { id: 0, title: 'Acudió a la Laguna de Tecocomulco para revisar los avances en la trituración del Lirio Acuático', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1574497022291460096?s=20', start: '2022-09-26', color: 'var(--wine)', },
    { id: 0, title: 'Acompañó al Gobernador Julio Menchaca y al Secretario de Gobierno Guillermo Olivares Reyna a la Instalación del Consejo Estatal de Protección Civil 2022-2028', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1574893471483174912?s=20', start: '2022-09-27', color: 'var(--wine)', },
    { id: 0, title: 'Recibió en la SEMARNATH al presidente del CCEHidalgo Alejandro Sánchez Ramírez y el presidente de la AMDA Hidalgo Valerio Charolet Islas para dialogar sobre proyectos orientados al desarrollo sustentable del estado.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1575217947840167938?s=20', start: '2022-09-28', color: 'var(--wine)', },
    { id: 0, title: 'Realizó un recorrido en el Vivero Las Fuentes en Singuilucan.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1577384723483082753?s=20', start: '2022-10-04', color: 'var(--wine)', },
    { id: 0, title: 'Acudió a la Primera Sesión Ordinaria de la ANAAEMX, Región Centro.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1578511291572162560?s=20', start: '2022-10-07', color: 'var(--wine)', },
    { id: 0, title: 'Asistió a una reunión de trabajo con las y los secretarios de gobierno.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1578809648534409217?s=20', start: '2022-10-08', color: 'var(--wine)', },
    { id: 0, title: 'Sostuvo reuniones con Margarita Ramos, presidenta municipal de La Misión, el diputado federal, Héctor Chávez y Heriberto Hernández para realizar alianzas estratégicas que ayuden a mitigar el impacto ambiental.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1579853412828471298?s=20', start: '2022-10-11', color: 'var(--wine)', },
    { id: 0, title: 'Realizó un recorrido por Tula para evaluar de forma eficaz las condiciones ambientales en esta región.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1580327622885937152?s=20', start: '2022-10-12', color: 'var(--wine)', },
    { id: 0, title: 'Sostuvo una reunión de trabajo con el titular de la Unidad de Planeación y Prospectiva Miguel Ángel Tello, para aportar y seguir construyendo el Plan Estatal de Desarrollo.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1580576312392982528?s=20', start: '2022-10-13', color: 'var(--wine)', },
    { id: 0, title: 'Acudió a las instalaciones de la PROESPA para entregar reconocimientos al personal que concluyó los cursos en Impacto Ambiental, Vida Silvestre, Forestal y Auditoría Ambiental.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1581008185984307203?s=20', start: '2022-10-14', color: 'var(--wine)', },
    { id: 0, title: 'Se reunió con los miembros de la Unidad Socioeconómica Ejidal Jonacapa para generar acuerdos que ayuden a que se regularicen las operaciones en la mina de cantera y los talleres que manejan este material.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1582450543846641665?s=20', start: '2022-10-17', color: 'var(--wine)', },
    { id: 0, title: 'Realizó una visita técnica a los proyectos de rellenos sanitarios en Zimapán y La Misión.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1583586447822450688?s=20', start: '2022-10-21', color: 'var(--wine)', },
    { id: 0, title: 'Asistió al Foro Nacional "Impactos del #CambioClimático: Vulnerabilidad y Adaptación".', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1583923675283492864?s=20', start: '2022-10-22', color: 'var(--wine)', },
    { id: 0, title: 'Acudió a una reunión y recorrido en el municipio de Alfajayucan donde se presentó el programa regional de Conservación de Depredadores Silvestre.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1584974005714702336?s=20', start: '2022-10-25', color: 'var(--wine)', },
    { id: 0, title: 'Visitó la Planta de Tratamiento de Aguas Residuales Atotonilco, con el fin de atender sus necesidades.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1585031289966833664?s=20', start: '2022-10-25', color: 'var(--wine)', },
    { id: 0, title: 'Participó en la primera carrera recreativa de 5 kilómetros SUMA20 que organizó la Secretaría de Salud de Hidalgo.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1586785290341220352?s=20', start: '2022-10-30', color: 'var(--wine)', },
    { id: 0, title: 'Realizó un recorrido por el Parque Nacional "El Chico" y platicó con las y los brigadistas que realizan brechas corta fuego.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1587268834808963072?s=20', start: '2022-10-31', color: 'var(--wine)', },
    { id: 0, title: 'Encabezó una reunión de coordinación con representantes de la Conagua, SEMARNAT, el ayuntamiento de Tula y la Dirección General de Gobernación Tula.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1588249021096796166?s=20', start: '2022-11-03', color: 'var(--wine)', },
    { id: 0, title: 'Participó en la instalación del Subcomité Sectorial de Medio Ambiente y Recursos Naturales del COPLADEHI, llevada a cabo en la SEMARNATH.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1590817133667164166?s=20', start: '2022-11-10', color: 'var(--wine)', },
    { id: 0, title: 'Realizó una visita a la Planta de Tratamiento Broquers Ambiental en Querétaro para conocer el proceso que ahí realizan.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1592268375278997508?s=20', start: '2022-11-13', color: 'var(--wine)', },
    { id: 0, title: 'Acompañó al Gobernador Julio Menchaca a la inauguración del Centro de Excelencia en el Desarrollo de Tecnología de Blockchain.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1592364931823063041?s=20', start: '2022-11-14', color: 'var(--wine)', },
    { id: 0, title: 'Sostuvo una mesa de trabajo con la SEMARNAT Federal, María Luisa Albores y el gobernador del estado Julio Menchaca.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1593081579915866112?s=20', start: '2022-11-16', color: 'var(--wine)', },
    { id: 0, title: 'Acompañó al gobernador del estado, Julio Menchaca, a la presentación del Camino de la Transformación por la Paz y Seguridad de Hidalgo.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1593771719994621952?s=20', start: '2022-11-18', color: 'var(--wine)', },
    { id: 0, title: 'Acompañó al Gobernador Julio Menchaca en la 33 Sesión Ordinaria del Sistema Estatal para Prevenir, Atender, Sancionar y Erradicar la Violencia contra las Mujeres y la 10 Sesión Ordinaria de la Comisión de Igualdad y no Discriminación', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1595915500835508225?s=20', start: '2022-11-24', color: 'var(--wine)', },
    { id: 0, title: 'Se reunió con Enrique Padilla titular de la Subsecretaría de Protección Civil y Gestión de Riesgos del Gobierno de Hidalgo.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1596222976244224000?s=20', start: '2022-11-25', color: 'var(--wine)', },
    { id: 0, title: 'Acudió a las instalaciones de Grupo Modelo para revisar el Programa Integral de Manejo para la Recarga del Acuífero Apan.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1598434865720229888?s=20', start: '2022-12-01', color: 'var(--wine)', },
    { id: 0, title: 'Presidió la Octava Sesión Extraordinaria 2022 de la junta de Gobierno realizada en la SEMARNATH.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1598453425842409486?s=20', start: '2022-12-01', color: 'var(--wine)', },
    { id: 0, title: 'Participó, en compañía de la presidente del DIF Edda Vite Ramos, en el inicio de actividades por el 20 aniversario del decreto del Parque Ecológico Cubitos como Área Natural Protegida.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1599894300527333376?s=20', start: '2022-12-05', color: 'var(--wine)', },
    { id: 0, title: 'Acompañó al gobernador Julio Menchaca y a la presidenta del DIF Hidalgo Edda Vite, a la entrega del Premio Estatal de la Juventud 2022.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1600650451401121793?s=20', start: '2022-12-07', color: 'var(--wine)', },
    { id: 0, title: 'Acompañó al gobernador Julio Menchaca a la Entrega de la Unidad de Investigación y Desarrollo de Tecnologías en la Universidad Politécnica de Pachuca.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1601042519566925824?s=20', start: '2022-12-08', color: 'var(--wine)', },
    { id: 0, title: 'Participó en las reuniones regionales de coordinación y toma de acuerdos entre dependencias federales, estatales y municipales en Tula.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1601429697052962816?s=20', start: '2022-12-09', color: 'var(--wine)', },
    { id: 0, title: 'Sostuvo una mesa de trabajo con el equipo de la SEMARNATH', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1611492232565252096?s=20', start: '2023-01-06', color: 'var(--wine)', },
    { id: 0, title: 'Supervisó la apertura de líneas negras en el barrio La Camelia, como parte de las acciones de prevención previo a la temporada de incendios forestales 2023.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1612629970572169216?s=20', start: '2023-01-09', color: 'var(--wine)', },
    { id: 0, title: 'Participó en la presentación del Método de regionalización 2022-2028, presidida por el titular de la Unidad de Planeación y Prospectiva Hidalgo, Miguel Ángel Tello Vargas.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1612901768446222339?s=20', start: '2023-01-10', color: 'var(--wine)', },
    { id: 0, title: 'Acompañó al gobernador Julio Menchaca al acto cívico por el 154 aniversario de la creación del estado de Hidalgo.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1615066699161829377?s=20', start: '2023-01-16', color: 'var(--wine)', },
    { id: 0, title: 'Se reunió con autoridades y vecinos de la Colonia Agrícola y Ganadera Cocinillas, en el municipio de Apan, para presentar un proyecto ecoturístico.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1615129869997703168?s=20', start: '2023-01-16', color: 'var(--wine)', },
    { id: 0, title: 'Recibió la visita del gobernador Julio Menchaca en la Semarnath y realizaron un recorrido por las instalaciones.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1615473958404464642?s=20', start: '2023-01-17', color: 'var(--wine)', },
    { id: 0, title: 'Realizó un recorrido por la Planta de Composta Bordo Poniente y la Estación y Planta de Selección Vallejo de la Ciudad De México.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1615790927133478923?s=20', start: '2023-01-18', color: 'var(--wine)', },
    { id: 0, title: 'Se reunió con la diputada local Vanesa Escalante, para tratar temas relacionados con el medio ambiente y sus repercusiones en la salud y bienestar de la sociedad hidalguense.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1615863544758558722?s=20', start: '2023-01-18', color: 'var(--wine)', },
    { id: 0, title: 'Acompañó al gobernador Julio Menchaca a la primera sesión ordinaria de la Comisión Interinstitucional para la Erradicación del Trabajo Infantil y Protección de Adolescentes Trabajadores en Edad Permitida en el Estado de Hidalgo.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1617668849780023298?s=20', start: '2023-01-23', color: 'var(--wine)', },
    { id: 0, title: 'Asistió a la firma de convenio con el Centro de Capacitación y Formación Permanente del Senado de México, presidida por el gobernador Julio Menchaca.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1618023975992578048?s=20', start: '2023-01-24', color: 'var(--wine)', },
    { id: 0, title: 'Sostuvo una reunión con el presidente de Huejutla Daniel Andrade Surutuza, para tratar temas en materia de residuos sólidos.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1618421891181707266?s=20', start: '2023-01-25', color: 'var(--wine)', },
    { id: 0, title: 'Realizó un recorrido por el Centro de Educación y Cultura Ambiental de Huejutla (CECAH), acompañada por la presidenta de San Felipe Orizatlán Erika Saab Lara y de Huejutla Daniel Andrade Zurutuza.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1618746990615232513?s=20', start: '2023-01-26', color: 'var(--wine)', },
    { id: 0, title: 'Se reunió con las y los directores de ecología de los municipios de esta región para trabajar en las problemáticas que presentan en materia ambiental.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1618756840875261952?s=20', start: '2023-01-26', color: 'var(--wine)', },
    { id: 0, title: 'Asistió al evento conmemorativo del Día Mundial de los Humedales, en la Laguna de Tecocomulco.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1621280844697542661?s=20', start: '2023-02-02', color: 'var(--wine)', },
    { id: 0, title: 'Realizó una gira de trabajo por la región Tula-Tepeji y visitó diversas empresas para supervisar el impacto ambiental en materia de plagas forestales, calidad del suelo, aire y contaminación hídrica.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1621638060394823681?s=20', start: '2023-02-03', color: 'var(--wine)', },
    { id: 0, title: 'En representación del gobernador Julio Menchaca Salazar, asistió a la 13 reunión anual del Grupo de Trabajo de Gobernadores sobre Clima y Bosques en Mérida, Yucatán', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1624872957444071426?s=20', start: '2023-02-12', color: 'var(--wine)', },
    { id: 0, title: 'Sostuvo mesas de trabajo con vecinos del municipio de Mineral de la Reforma y ejidatarios de Actopan, con el fin de atender las problemáticas en materia de residuos sólidos urbanos de cada demarcación.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1625307290004779011?s=20', start: '2023-02-13', color: 'var(--wine)', },
    { id: 0, title: 'Sostuvo una reunión con la Gobernatura Nacional Indígena de Mineral de la Reforma para proteger las zonas naturales de esa región.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1625199293790400512?s=20', start: '2023-02-13', color: 'var(--wine)', },
    { id: 0, title: 'Asistió a la primera Jornada de Vacunación de Arbolado Urbano en Pachuca, en el Jardín de los Hombres Ilustres.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1625956511838085120?s=20', start: '2023-02-15', color: 'var(--wine)', },
    { id: 0, title: 'Rindió protesta como parte de la Comisión Rectora para la Competitividad el Empleo y Desarrollo Económico y del Consejo Consultivo de Análisis Incentivos a la Inversión para la Competitividad, presididas por el gobernador Julio Menchaca.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1626037931998584832?s=20', start: '2023-02-15', color: 'var(--wine)', },
    { id: 0, title: 'Realizó un recorrido por la planta embotelladora “Las Margaritas”, para supervisar que los procesos de producción y reciclaje sean lo óptimos de acuerdo a los programas de manejo presentados por la empresa.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1627835225593233408?s=20', start: '2023-02-20', color: 'var(--wine)', },
    { id: 0, title: 'Acompañó al gobernador Julio Menchaca a la Ceremonia de Designación del Centro de Excelencia Blockchain Hidalgo como miembro de la Red de Centros de Excelencia en Tecnologías Transformadoras de la OEA.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1628522092248301568?s=20', start: '2023-02-22', color: 'var(--wine)', },
    { id: 0, title: 'Participó en la Reunión de Coordinación y Vinculación Interinstitucional para la integración del plan rector para la recuperación y activación de la Laguna de Tecocomulco con enfoque integrado de cuenca.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1628887765399093249?s=20', start: '2023-02-23', color: 'var(--wine)', },
    { id: 0, title: 'Presidió la Primera sesión Ordinaria del Subcomité Sectorial de Medio Ambiente y Recursos Naturales del COPLADEHI.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1630336706409897986?s=20', start: '2023-02-27', color: 'var(--wine)', },
    { id: 0, title: 'Participó en el conversatorio 8M "Desde la mirada de las mujeres del gabinete".', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1631145413985153024?s=20', start: '2023-03-01', color: 'var(--wine)', },
    { id: 0, title: 'Se reunió con el subsecretario de Regulación Ambiental de la Semarnat Alonso Jiménez para establecer acuerdo para el desarrollo de acciones en materia de calidad del aire y conservación de los recursos naturales.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1630972895965986816?s=20', start: '2023-03-01', color: 'var(--wine)', },
    { id: 0, title: 'Recibió en la Semarnath a alumnas y alumnos de la Universidad Tecnológica Minera de Zimapán.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1631433726692319233?s=20', start: '2023-03-02', color: 'var(--wine)', },
    { id: 0, title: 'Supervisó los trabajos de prevención de incendios forestales realizados en el ejido Santo Tomás en Zempoala.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1631401941119975424?s=20', start: '2023-03-02', color: 'var(--wine)', },    
    { id: 0, title: 'Presentó el Proyecto de Protocolo para la Protección de las Personas Defensoras del Medio Ambiente.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1631768682828296192?s=20', start: '2023-03-03', color: 'var(--wine)', },
    { id: 0, title: 'Acompañó al gobernador Julio Menchaca a la presentación de la Estrategia Estatal para el Bienestar de las Mujeres, realizada en la Huasteca Hidalguense.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1632942498246012935?s=20', start: '2023-03-06', color: 'var(--wine)', },
    { id: 0, title: 'Encabezó una reunión de trabajo con el equipo de la Comisión Nacional Forestal, la Subsecretaría de Protección Civil y Bomberos de Hidalgo, para tomar acuerdos que agilicen estos trabajos de prevención y control de incendios forestales.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1634284641346637824?s=20', start: '2023-03-08', color: 'var(--wine)', },
    { id: 0, title: 'Asistió al evento Residuos Expo, para observar y conocer todas las estrategias en innovación y tecnología implementadas por diversas empresas para la gestión, manejo y disposición final de los residuos en el país.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1634374948901863427?s=20', start: '2023-03-09', color: 'var(--wine)', },
    { id: 0, title: 'Asistió a la conferencia "Liderazgo en la administración pública", impartida por la titular del Instituto Hidalguense de las Mujeres Bertha Miranda Rodríguez, a compañeras de la Semarnath.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1636041346216607761?s=20', start: '2023-03-15', color: 'var(--wine)', },
    { id: 0, title: 'Encabezó una mesa de trabajo con funcionarios estatales y federales, para avanzar en la gestión del proyecto “Aprovechamiento de biosólidos”.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1636086935612530690?s=20', start: '2023-03-15', color: 'var(--wine)', },
    { id: 0, title: 'Se reunió con las presidentas municipales de Tasquillo Isabel Guerrero y de Villa de Tezontepec, Elsa Dolores, para abordar el tema de la disposición final de los residuos sólidos urbanos en sus demarcaciones.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1636172991527518215?s=20', start: '2023-03-15', color: 'var(--wine)', },
    { id: 0, title: 'En coordinación con habitantes de la colonia agrícola Cocinillas en Apan, dió inicio al trazo y construcción del proyecto de turismo de naturaleza “Sendero interpretativo”, que consta de un recorrido de 1.73 kilómetros.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1636445312712581163?s=20', start: '2023-03-16', color: 'var(--wine)', },
    { id: 0, title: 'Recibió de la Comisión Nacional Forestal en Hidalgo, el préstamo de herramientas que faciliten estos trabajos a brigadistas de la Semarnath.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1636825167182610432?s=20', start: '2023-03-17', color: 'var(--wine)', },
    { id: 0, title: 'Tomó protesta como vocal del Consejo Técnico Artesanal, instalado por el gobernador Julio Menchaca, el cual pretende impulsar el potencial empresarial y económico de las y los artesanos hidalguenses.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1636957836008644608?s=20', start: '2023-03-17', color: 'var(--wine)', },
    { id: 0, title: 'Realizó la firma de convenio con el Instituto Hidalguense para el desarrollo municipal, para la profesionalización de 71 municipios que serán evaluados a través del Estándar de Competencias Laborales “Ejecución de las atribuciones de Ecología y Medio Ambiente”.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1637976939968208897?s=20', start: '2023-03-20', color: 'var(--wine)', },
    { id: 0, title: 'Acudió a la ceremonia conmemorativa del 217⁰ Aniversario del Natalicio de Benito Pablo Juárez García, encabezada por el titular de la Secretaría de Gobierno de Hidalgo, Guillermo Olivares Reyna.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1638289303783497730?s=20', start: '2023-03-21', color: 'var(--wine)', },
    { id: 0, title: 'Asistió al evento Caritas Felices Transformando el Futuro, el cual busca generar conciencia, sensibilidad y cerrar la brecha de desigualdad entorno a este tema.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1638322943947096064?s=20', start: '2023-03-21', color: 'var(--wine)', },   
    { id: 0, title: 'Encabezó la primera sesión ordinaria 2023 del Consejo Estatal Forestal de Hidalgo, donde fueron presentados los datos más relevantes de los comités de incendios forestales, sanidad y aprovechamientos forestales.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1639419120461684736?s=20', start: '2023-03-24', color: 'var(--wine)', },   
    { id: 0, title: 'Sostuvo una mesa de trabajo con la Semarnat federal, para contemplar el “Programa y Zona de Restauración Ecológica” en Hidalgo, que abordará estrategias de reforestación de acuerdo a la compatibilidad de los proyectos de manejo según la zona que sea atendida', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1639297384088846337?s=20', start: '2023-03-24', color: 'var(--wine)', }, 
    { id: 0, title: 'Participó en el Programa de Transformación y Conservación del Parque Ecológico Cubitos, Manos por la Conservación, donde se llevó a cabo la apertura de zanjas para el desvío de captación pluvial, la recolección de residuos sólidos y rehabilitación de perímetros de las oficinas.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1641204343876583431?s=20', start: '2023-03-29', color: 'var(--wine)', }, 
    { id: 0, title: 'Acudió a una reunión en la Comisión Ambiental Metropolitana (CAM) para tratar temas de interés común en favor del medio ambiente en dicha zona.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1641558004339204096?s=20', start: '2023-03-30', color: 'var(--wine)', }, 
    { id: 0, title: 'Sostuvo una mesa de trabajo virtual con el equipo de la Semarnat federal para sumarse a las estrategias federales propuestas para regular y controlar las principales fuentes contaminantes al aire, agua y suelo en Hidalgo.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1641866702647353344?s=20', start: '2023-03-31', color: 'var(--wine)', }, 
    { id: 0, title: 'Sostuvo una reunión con diputados integrantes de la Comisión de Asuntos Metropolitanos en el Congreso de Hidalgo.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1641926020650397697?s=20', start: '2023-03-31', color: 'var(--wine)', }, 
    { id: 0, title: 'Asistió a la Jornada Estatal de Acción Climática desde las y los Defensores del Territorio en Atotonilco de Tula, organizada por el INECC.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1642335848350842880?s=20', start: '2023-04-01', color: 'var(--wine)', },
    { id: 0, title: 'Participó en el curso-taller para la formulación de planteles operativos de emergencias por inundación, llevado a cabo en la Universidad Tecnológica de Tula-Tepeji.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1645574458030039040?s=20', start: '2023-04-10', color: 'var(--wine)', },
    { id: 0, title: 'Inauguró las actividades de la Semana Internacional del Cielo Oscuro en Hidalgo 2023.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1647320779158171650?s=20', start: '2023-04-15', color: 'var(--wine)', },
    { id: 0, title: 'Acompañó al gobernador Julio Menchaca a la firma de la proclamación de la Semana Internacional del Cielo Oscuro en Hidalgo 2023, llevada a cabo en el Parque Ecológico Cubitos, para consolidar acciones que mitiguen la contaminación lumínica..', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1649630465509212160?s=20', start: '2023-04-21', color: 'var(--wine)', },
    { id: 0, title: 'Acompañó a la presidenta del patronato del Sistema DIF Hidalgo Edda Vite Ramos, a la titular de la Coesbioh Erika Ortigoza Vázquez y al presidente municipal de Pachuca Sergio Baños, en la inauguración del primer Oasis Urbano en Hidalgo.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1650640401563373568?s=20', start: '2023-04-24', color: 'var(--wine)', },
    { id: 0, title: 'Realizó una gira de trabajo por la Reserva de la Biosfera Barranca de Metztitlán, con los presidentes de Metztitlán y Eloxochitlán, representantes de Conagua, Semarnat federal y CEAA Hidalgo para conocer los desafíos ambientales.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1651349434058276865?s=20', start: '2023-04-26', color: 'var(--wine)', },
    { id: 0, title: 'Recibió a hijas e hijos del personal que integra la Semarnath, para ser parte del PEC Aventuras, que tiene por objetivo incentivar a las infancias en el cuidado y protección del medio ambiente.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1652039798293405703?s=20', start: '2023-04-28', color: 'var(--wine)', },
    { id: 0, title: 'Realizó una visita a la empresa Cementos Fortaleza con la finalidad de mantener cercanía con el sector productor de cemento y generar una agenda de trabajo para la protección del medio ambiente.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1654191022363103250?s=20', start: '2023-05-04', color: 'var(--wine)', },
    { id: 0, title: 'Asistió al "Foro por un Futuro Sostenible" en la Universidad Politécnica Metropolitana de Hidalgo, donde realizó la donación de 30 plantas con la finalidad de reforestar y conscientizar a la comunidad estudiantil sobre la importancia de los espacios verdes.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1658256697348050945?s=20', start: '2023-05-15', color: 'var(--wine)', },
    { id: 0, title: 'En conjunto con la secretaria de Turismo de Hidalgo Elizabeth Quintanar encabezó la "Caminata Turística por la Salud" en el Parque Ecológico Cubitos.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1658280604847464448?s=20', start: '2023-05-15', color: 'var(--wine)', },
    { id: 0, title: 'Acompañó al gobernador Julio Menchaca Salazar y a la secretaria de la Semarnat federal María Luisa Albores a la firma de convenios para conservar y preservar el medio ambiente de Hidalgo.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1658589179746439174?s=20', start: '2023-05-16', color: 'var(--wine)', },
    { id: 0, title: 'Asistió a la primera sesión del Consejo de Desarrollo Metropolitano del Valle de México 2023, presidida por el gobernador de Hidalgo Julio Menchaca Salazar, la jefa de gobierno de la Ciudad de México Claudia Sheinbaum y Alfredo del Mazo, gobernador del Estado de México.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1659696512799215617?s=20', start: '2023-05-19', color: 'var(--wine)', },
    { id: 0, title: 'Llevó a cabo una serie de reuniones de trabajo para escuchar y atender solicitudes ciudadanas enfocadas en diversos temas ambientales.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1661159072257781766?s=20', start: '2023-05-23', color: 'var(--wine)', },
    { id: 0, title: 'Participó en la Mesa Intersecretarial de Inversiones con Desarrolladores Industriales en Tepeji.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1662264942102294528?s=20', start: '2023-05-23', color: 'var(--wine)', },  
    { id: 0, title: 'Llevó a cabo la inauguración de la Feria Ambiental 2023, en el Parque Ecológico Cubitos, como parte de la conmemoración del Día Mundial del Medio Ambiente.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1667344519182921731?s=20', start: '2023-06-09', color: 'var(--wine)', },  
    { id: 0, title: 'Acompañó al gobernador Julio Menchaca Salazar en la presentación de la ruta Hidalgo 2030, Transformación para un Desarrollo Sostenible e Inclusivo.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1668389028453044224?s=20', start: '2023-06-12', color: 'var(--wine)', },  
    { id: 0, title: 'Dio inicio al Programa de Intervención Ambiental con Enfoque Integrado de Cuenca (PIAEIC), en la localidad de Ventoquipa, en el municipio de Santiago Tulantepec, donde se realizó el retiro de lirio acuático y lentejilla. Además, de plagas forestales como el heno motita.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1668308845473693696?s=20', start: '2023-06-12', color: 'var(--wine)', },
    { id: 0, title: 'Participó en la instalación del Comité de Planeación para el Desarrollo Regional (COPLADER) en la región de Actopan.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1669110854254460928?s=20', start: '2023-06-14', color: 'var(--wine)', },
    { id: 0, title: 'Acompañó al gobernador Julio Menchaca Salazar a su gira Rutas de la Transformación en el municipio de La Misión, donde realizó la donación histórica de 25 mil árboles para reforestar dicha región.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1669885479905370117?s=20', start: '2023-06-16', color: 'var(--wine)', },
    { id: 0, title: 'Participó en la instalación del Comité de Planeación para el Desarrollo Regional (COPL.ADER) en la región Tula.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1671723989293576193?s=20', start: '2023-06-21', color: 'var(--wine)', },
    { id: 0, title: 'Llevó a cabo la donación de 400 plantas forestales al bachillerato “Prof. Rafael Ramírez” en Pachuca, en el marco del programa Reforestar para Transformar.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1672377856821972992?s=20', start: '2023-06-23', color: 'var(--wine)', },
    { id: 0, title: 'Participó en la Mesa de Acercamiento a la Población en el municipio de Tulancingo, como un ejercicio de transparencia y compromiso con el pueblo hidalguense.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1672339449076920322?s=20', start: '2023-06-23', color: 'var(--wine)', },
    { id: 0, title: 'En el marco del programa Reforestar para Transformar, realizó la liberación de 13 mil 733 plantas forestales en el vivero de Las Fuentes, en Singuilucan.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1673830957894488064?s=20', start: '2023-06-27', color: 'var(--wine)', },
    { id: 0, title: 'En el marco del 41 aniversario del Parque Nacional "El Chico", entregó mil cedros blancos para ser plantados en esa Área Natural Protegida.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1676005605796900866?s=20', start: '2023-07-03', color: 'var(--wine)', },
    { id: 0, title: 'Durante la gira Rutas de la Transformación, encabezada por el gobernador Julio Menchaca, presentó el proyecto de habilitación de la nueva celda del relleno sanitario de Huehuetla.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1676730585882980352?s=20', start: '2023-07-05', color: 'var(--wine)', },
    { id: 0, title: 'Participó en la reunión mensual de gabinete encabezada por el gobernador Julio Menchaca.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1676745959886266368?s=20', start: '2023-07-05', color: 'var(--wine)', },
    { id: 0, title: 'Realizó la entrega de herramientas a brigadistas de incendios forestales, las cuáles fueron donadas por cuatro empresas hidalguenses.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1678542223611158528?s=20', start: '2023-07-10', color: 'var(--wine)', },
    { id: 0, title: 'Asistió a la Guardia de Honor en conmemoración del Día de la Abogada y el Abogado, llevada a cabo frente al monumento del presidente Benito Juárez.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1679235014297362432?s=20', start: '2023-07-12', color: 'var(--wine)', },
    { id: 0, title: 'Participó en la limpieza de hiedra en el Parque Nacional "El Chico" como parte de las actividades por su 41 aniversario.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1679625911073644545?s=20', start: '2023-07-13', color: 'var(--wine)', },
    { id: 0, title: 'Visitó la planta de reciclaje de PET: PetStar, para conocer sus instalaciones y procedimientos de aprovechamiento.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1680322151201603584?s=20', start: '2023-07-15', color: 'var(--wine)', },
    { id: 0, title: 'Acompañó al gobernador Julio Menchaca y a brigadistas de incendios forestales de la Semarnath a disfrutar del partido del Club Pachuca, como reconocimiento a quienes protegen los bosques hidalguenses.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1680926472809193474?s=20', start: '2023-07-16', color: 'var(--wine)', },
    { id: 0, title: 'Asistió a la presentación que realizó el gobernador Julio Menchaca de la Academia de la Transformación.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1681033131741028353?s=20', start: '2023-07-17', color: 'var(--wine)', },
    { id: 0, title: 'Participó en la primera sesión ordinaria del órgano de gobierno de la CAMegalópolis.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1681143401201164288?s=20', start: '2023-07-17', color: 'var(--wine)', },
    { id: 0, title: 'Acompañó al gobernador Julio Menchaca a la inauguración de las oficinas de la Procuraduría Agraria Representación Hidalgo, encabezada por Andres Velázquez Velázquez.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1682555949033111552?s=20', start: '2023-07-21', color: 'var(--wine)', },
    { id: 0, title: 'Acompañó al gobernador Julio Menchaca al recorrido de supervisión de los trabajos de rehabilitación del Jardín de los Hombres Ilustres.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1683596493490364418?s=20', start: '2023-07-24', color: 'var(--wine)', },
    { id: 0, title: 'Participó en el pronunciamiento de "Cero Tolerancia" y la reinstalación del Consejo General de Planeación para la Agenda de Género de la Coesbioh.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1684299867055595520?s=20', start: '2023-07-26', color: 'var(--wine)', },
    { id: 0, title: 'Acompañó al gobernador Julio Menchaca a la Toma de Compromiso del Consejo Directivo de COPARMEX Hgo 2023-2025.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1684639878426906626?s=20', start: '2023-07-27', color: 'var(--wine)', },
    { id: 0, title: 'Participó en las Rutas de la Transformación que encabeza el gobernador Julio Menchaca y presentó el proyecto de aprovechamiento de biosólidos, así como el programa Reforestar para Transformar en el que se lleva a cabo la donación de plantas forestales.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1686566844696612864?s=20', start: '2023-08-01', color: 'var(--wine)', },
    { id: 0, title: 'Llevó a cabo la firma de convenio de colaboración con el Colegio de Postgraduados, campus San Luis Potosí, para fortalecer el diseño y ejecución de estrategias que contribuyan a la conservación de la vida silvestre en Hidalgo.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1686888845739261955?s=20', start: '2023-08-02', color: 'var(--wine)', },
    { id: 0, title: 'Acompañó al gobernador Julio Menchaca a su gira Rutas de la Transformación en la que se entregaron reconocimientos y equipo de protección a brigadistas de malezas acuáticas de la Semarnath.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1687670856086962176?s=20', start: '2023-08-04', color: 'var(--wine)', },
    { id: 0, title: 'Se reunió con el gobernador Julio Menchaca y el gabinete estatal para presentar los avances de los proyectos establecidos para llevar a Hidalgo a su máximo potencial.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1687992539104579584?s=20', start: '2023-08-05', color: 'var(--wine)', },
    { id: 0, title: 'Se reunió con reAcompañó al gobernador Julio Menchaca a su gira de Rutas de la Transformación por Zimapán, donde fueron liberadas más de 5 mil plantas forestales, a través del programa Reforestar para Transformar.presentantes de la Inifap, la Conafor, la Coesbioh y la Cconanp, para establecer una ruta de trabajo que proteja y preserve los recursos forestales del Parque Nacional “El Chico”.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1687911097439838213?s=20', start: '2023-08-05', color: 'var(--wine)', },
    { id: 0, title: 'Realizó la entrega de 4 mil árboles al ayuntamiento municipal de Epazoyucan en el marco del programa Reforestar para Transformar.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1689432038657372160?s=20', start: '2023-08-09', color: 'var(--wine)', },
    { id: 0, title: 'En las Rutas de la Transformación, encabezadas por el gobernador Julio Menchaca, llevó a cabo la liberación de más de 3 mil plantas forestales en el municipio de San Salvador.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1689796429290635264?s=20', start: '2023-08-10', color: 'var(--wine)', },
    { id: 0, title: 'Acompañó al gobernador Julio Menchaca a su gira de Rutas de la Transformación por Zimapán, donde fueron liberadas más de 5 mil plantas forestales, a través del programa Reforestar para Transformar.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1691650343703105554?s=20', start: '2023-08-15', color: 'var(--wine)', },
    { id: 0, title: 'Acompañó al secretario de Desarrollo Económico de Hiodalgo, Carlos Henkel, en un encuentro con representantes de la industria minera en México para presentar el proyecto y la plataforma del Clúster Minero de Hidalgo.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1692268931929530711?s=20', start: '2023-08-16', color: 'var(--wine)', },
    { id: 0, title: 'Entregó herramientas a los asistentes del curso básicos de incendios forestales "Capacitar para Preservar y Transformar" en el municipio de Jacala', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1694081259012550756?s=20', start: '2023-08-18', color: 'var(--wine)', },
    { id: 0, title: 'Asistió al Primer Informe del gobernador Julio Menchaca, donde se presentaron los resultados que mejoran vidas.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1699189581910872557?s=20', start: '2023-09-05', color: 'var(--wine)', },
    { id: 0, title: 'Se reunió con el secretario de Medio Ambiente de Veracruz, Juan Carlos Contreras Bautista para compartir experiencias en la implementación de políticas públicas y estrategias para la prevención y el combate de plagas forestales en ambas entidades vecinas.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1699970437654913191?s=20', start: '2023-09-07', color: 'var(--wine)', },
    { id: 0, title: 'En representación del gobernador Julio Menchaca, acudió al informe de gobierno del presidente municipal de Pacula, Francisco Casas Chávez.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1700330421232320521?s=20', start: '2023-09-08', color: 'var(--wine)', },
    { id: 0, title: 'En coordinación con el titular del DIF Hidalgo, Javier Rodríguez, llevaron a cabo la plantación de un árbol Grevillea en el Jardín de los Hombres Ilustres.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1704141100494012657?s=20', start: '2023-09-13', color: 'var(--wine)', },
    { id: 0, title: 'Realizó la entrega de 6 mil plantas de clima tropical, de las especies caoba, cedro rojo y palo de rosa en el municipio de Huejutla.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1702028835716231615?s=20', start: '2023-09-18', color: 'var(--wine)', },
    { id: 0, title: 'Participó en el segundo Simulacro Nacional que se llevó a cabo en la Semarnath.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1704198921969340720?s=20', start: '2023-09-19', color: 'var(--wine)', },
    { id: 0, title: 'En coordinación con el director general del Instituto Mexicano de Tecnología del Agua, llevó a cabo la firma del convenio marco de colaboración institucional IMTA-SEMARNATH.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1705268302270890145?s=20', start: '2023-09-21', color: 'var(--wine)', },
    { id: 0, title: 'Asistió a la sesión solemne del Congreso del Estado de Hidalgo, en el que se llevó a cabo la entrega de la Medalla al Mérito de Protección Civil 2023, como reconocimiento está importante labor.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1707591025227907349?s=20', start: '2023-09-28', color: 'var(--wine)', },
    { id: 0, title: 'Presidió la segunda sesión ordinaria del subcomité sectorial de medio ambiente y recursos naturales del Comité de Planeación para el Desarrollo del Estado de Hidalgo (COPLADEHI).', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1707548868794048935?s=20', start: '2023-09-28', color: 'var(--wine)', },
    { id: 0, title: 'Asistió a la Universidad Tecnologica de Mineral de la Reforma al evento realizado en el marco del Día Nacional del Maíz 🌽, recurso natural que forma parte fundamental de la gastronomía y cultura mexicana.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1707894434388455651?s=20', start: '2023-09-29', color: 'var(--wine)', },
    { id: 0, title: 'Inauguró el Primer Foro Virtual de Intercambio de Experiencias en el Manejo del Fuego, donde participaron autoridades ambientales de Puebla, Tlaxcala, Estado de México, Morelos, Veracruz y la Ciudad de México.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1711913698858578258?s=20', start: '2023-10-10', color: 'var(--wine)', },
    { id: 0, title: 'Acudió al Parque Ecológico Cubitos a la observación guiada del Eclipse Solar Anular organizado por la Semarnath en coordinación con la Sociedad Astronómica del Estado de Hidalgo.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1713346267902869740?s=20', start: '2023-10-14', color: 'var(--wine)', },
    { id: 0, title: 'Compareció ante la LXV legislatura del Congreso del Estado de Hidalgo, donde presentó los resultados y avances a un año de la administración que encabeza el gobernador Julio Menchaca.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1715031858042331355?s=20', start: '2023-10-18', color: 'var(--wine)', },
    { id: 0, title: 'Mediante un recorrido, detectó un tiradero clandestino y el derrame de aguas residuales en el perímetro del Parque Ecológico Cubitos, por lo que se inició un levantamiento topográfico para trazar un proyecto que considere la reforestación del sitio, así como un sistema que canalice las aguas residuales, como remediación ecológica de la zona.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1716860231014133776?s=20', start: '2023-10-24', color: 'var(--wine)', },
    { id: 0, title: 'Encabezó el evento titulado "Camino al Mictlán" en el marco de la celebración de Día de Muertos en el Parque Ecológico Cubitos.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1718312909820854700?s=20', start: '2023-10-28', color: 'var(--wine)', },
    { id: 0, title: 'Participó en la donación destinada a las y los afectados por el devastador paso del Huracán Otis, en el centro de acopio instalado en el Parque Ecológico Cubitos.', place: 'Pachuca de Soto', description: 'https://twitframe.com/show?url=https://twitter.com/medioambiente_H/status/1720102998422999168?s=20', start: '2023-11-02', color: 'var(--wine)', },
    { id: 0, title: 'Inauguró la exposición pictórica "Arte Natural", organizada por la Procuraduría Estatal de Protección al Ambiente (Proespa) y la Academia Salvador Dalí.', place: 'Pachuca de Soto', description: 'https://www.facebook.com/medioambienteH/posts/647513544219041?ref=embed_post', start: '2023-11-06', color: 'var(--wine)', },
    { id: 0, title: 'Asistió al taller de fortalecimiento institucional municipal, estrategia de alcance regional.', place: 'Pachuca de Soto', description: 'https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FMonyMixtega%2Fposts%2Fpfbid0UJwaFpSPXapP4uzpuYU2dygiPgB1b6J9kNRtWMDSfRxkaWzWDcorBpWxjRq5dC4Ll', start: '2023-11-08', color: 'var(--wine)', },
    { id: 0, title: 'Asistio a la clausura de actividades del Congreso Internacional Falling Walls', place: 'Pachuca de Soto', description: 'https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FmedioambienteH%2Fposts%2Fpfbid02e1T185e6tT5JW4KUz2oc8BUMNRGP6HhDheoeJ9CARHvEuqkV6XroKC1TmQcojiwsl', start: '2024-11-25', color: 'var(--wine)', },
    { id: 0, title: 'Recorrio los margenes en su visita para observar la problematica que repesenta la proliferación del lirio acuatico en la presa Endho', place: 'Tula de Allenda', description: 'https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FmedioambienteH%2Fposts%2Fpfbid035Zkuvtf7eaXAbmaMZmcS5GjwnkkeFpKggEurH3L58YrZrzM7rgxtmGTG9Pay73qml', start: '2024-11-27', color: 'var(--wine)', },
    { id: 0, title: 'Encabezamos la reunión de trabajo para abordar la problematica del lirio acuatico y mosquito culex que afectan a la presa Endho', place: 'Tula de Allenda', description: 'https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FmedioambienteH%2Fposts%2Fpfbid02HjN9hYu9GuwUiNJWCNzf7yNYUry3MnGezcqv9D3ekHvyahuBpGCfHfY2fJYU7uqzl', start: '2024-12-01', color: 'var(--wine)', },
    { id: 0, title: 'Acudió a la presentación de los resultados de los indices de Estado y Ciudades Sostenibles de Hidalgo por el Centro Mario molina C.V. en un taller organizado por nuestra Secretaría', place: 'Pachuca', description: 'https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FmedioambienteH%2Fposts%2Fpfbid0ema9YtNWu41uFJLnZUgGTxCwM6aUkR6VLFQBiRqWWzxVQjaYdkkcPuMLNtMryEBkl', start: '2024-12-02', color: 'var(--wine)', },
    { id: 0, title: 'Asistío a la clausura de la Semana de la Conservación del Jaguar, organizada por COESBIOH', place: 'Pachuca', description: 'https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FmedioambienteH%2Fposts%2Fpfbid02NyqwGJ27657SacoeZn8KoTY7Qoqur7yh2UvGb3aEpDNm3h2AtfB19ZNW9aBkJDxYl', start: '2024-12-03', color: 'var(--wine)', },

];
