export const informacionArray = [

	{
		"route": './Adquisiciones',
		"nombre": 'Programa Anual de Adquisiciones',
	},



	{
		"pdf": '/pdf/codigo_de_etica.pdf',
		"nombre": 'Codigo de Etica de la Administracion Publica del Estado de Hidalgo',

	},
	{
		"pdf": '/pdf/codigo_conducta.pdf',
		"nombre": 'Codigo de Conducta de la SEMARNATH',

	},


	{
		"pdf": '/pdf/SEANP.pdf',
		"nombre": 'Areas Naturales Protegidas Estatales y Municipales',

	},

	{
		"pdf": '/pdf/ProgramaSectorial2023-2028.pdf',
		"nombre": 'Programa Sectorial de Desarrollo - Medio Ambiente y Recursos Naturales 2023 - 2028',

	},


	
]