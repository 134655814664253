export const carouselImages = [
    {
        "imagen": "../assets/imgs/1.jpg",
        "url": "https://verificacionvehicular.hidalgo.gob.mx" 
    },
    {
        "imagen": "../assets/imgs/2.jpg",
        "url": "https://bitacora.semarnath.gob.mx/"
    },
    {
        "imagen": "https://qr.semarnath.gob.mx/images/Wallpaper_Escritorio6.jpg",
        "url": "" 
    },
    {
        "imagen": "https://qr.semarnath.gob.mx/images/Wallpaper_Escritorio2.jpg",
        "url": ""
    },
    {
        "imagen": "https://qr.semarnath.gob.mx/images/Wallpaper_Escritorio3.jpg",
        "url": ""
    },

    {
        "imagen": "../assets/imgs/7.jpg",
        "url": ""
    },

]