export const OtrosProgramasArray = [
    {
        "Programa": "Programa 1",
        "Link": "https://ejemplo.com/programa1",
    },
    {
        "Programa": "Programa 2",
        "Link": "https://ejemplo.com/programa2",
    },
    {
        "Programa": "Programa 3",
        "Link": "https://ejemplo.com/programa3",
    },
]