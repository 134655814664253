import React, { Component } from 'react';
import { adquisicionesArray } from '../../json/adquisiciones';
import './Adquisiciones.css';

class Adquisiciones extends Component {
    render() {
        return (
            <div className="container adquisiciones-container">
                <div className="row">
                    <div className="col_12">
                        <div className="adquisiciones.title">
                            <h1>Programa Anual de Adquisiciones</h1>
                            <hr className='hr-gob' />
                        </div>
                    </div>
                </div>

                <div className="card_container_informacion">
                    <div className='row'>
                        {adquisicionesArray.map((item, index) => (
                            <div key={index} className='card_informacion'>
                                <div className='card_nombre'>
                                    <h5>{item.nombre}</h5>
                                    <span className="badge bg-secondary">
                                        <a href={item.pdf} target="_blank" rel="noopener noreferrer">.pdf</a>
                                    </span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

export default Adquisiciones;
