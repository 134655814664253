import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { programasArray } from '../../json/programasItem';
import './Programas.css';


class Programas extends Component {
    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col_12">
                        <div className="programas.title"><br></br><br></br>
                            <h1>Programas </h1>
                            <hr className='hr-gob' />
                        </div>
                    </div>
                </div>

                <div className='row'>

                    {

                        programasArray.map((item, index) => (

                            <div key={index} className='col-md-3 col-sm-6 col-12'>
                            <div>
                                <div className="card_container_programas">
                                    <div className='card_nombre'>
                                        <h5>{item.programa}</h5>
                                        <p>{ }<span className="badge bg-secondary">

                                            <Link target="_blank" to={item.link}>Ir a la pagina</Link></span></p>

                                    </div>
                                    </div>
                                </div>

                            </div>


                        ))}

                    <div >
                        <Link className='rubros_historicos' to="OtrosProgramas"><a></a></Link>
                    </div>

                    <br></br>

                </div>
                <br></br>
            </div>
        );
    }
}

export default Programas