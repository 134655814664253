import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ayudasArray } from '../../json/ayudas';
import './Ayudas.css';



class Ayudas extends Component {
    render() {
        return (
            <div className="container ayudas-container">
                            <div className="row">
                    <div className="col_12">
                        <div className="ayudas.title">
                            <h1>Ayudas y Subsidios</h1>
                            <hr className='hr-gob'/>
                        </div>
                      
                    </div>
                </div>

                <div className="card_container_ayudas">
                <div className='row'>
                    {ayudasArray.map((item, index) => (
                        <div key={index} className='card_informacion'>
                            <div className='card_informacion'>
                                <div className='card_nombre'>
                                    <h5>{item.nombre}</h5>
                                    <span className="badge bg-secondary">
                                    <Link to={item.pdf}>.pdf</Link></span>
                                </div>
                            </div>
                        </div>
                    ))}</div>
                </div>
            </div>
        );
    }
}

export default Ayudas