export const programasArray = [

    {
        "link": 'https://bitacora.semarnath.gob.mx',
        "programa": 'Bitacora Ambiental del Estado de Hidalgo',
    },
    {
        "link": 'https://www.aireysalud.semarnath.gob.mx',
        "programa": 'Monitoreo de la Calidad del Aire del Estado de Hidalgo',
    },
    {
        "link": 'https://www.verificacionvehicular.semarnath.gob.mx',
        "programa": 'Programa de Verificacion Vehicular',
    },
    {
        "link": 'http://cambioclimatico.semarnath.gob.mx/',
        "programa": 'Estrategia Estatal ante el Cambio Climatico',
    },


]