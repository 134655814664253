import React, { useState, useEffect } from 'react';
import './carousel.css';
import Serch from '../Serch/Serch';

const Carousel = ({bannerData, serch, name}) => {
  const [miImages, setMiImages] = useState([]);

  useEffect(() => {
    setMiImages(bannerData);
  }, [bannerData]);

  const handleClick = (url) => {
    if (url) {
      window.open(url, '_blank', 'noopener,noreferrer');
    }
  };
  return (
    <div id={name} className="carousel slide carouselBos" data-bs-ride="carousel">
      <div className="carousel-inner">
        {
          miImages.map((item, index) => (
            <div 
              key={index} 
              className={index === 0 ? "carousel-item active" : "carousel-item"}
              onClick={() => handleClick(item.url)}
              style={{cursor: item.url ? 'pointer' : 'default'}}
            >
              <div 
                className="img-carousel d-block w-100" 
                style={{ backgroundImage: `url("${item.imagen}")` }} 
                alt={`Slide ${index + 1}`}
              >
              </div>
            </div>
          ))
        }
        {serch && <Serch />}
      </div>
      <button className="carousel-control-prev" type="button" data-bs-target={'#' + name} data-bs-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button className="carousel-control-next" type="button" data-bs-target={'#' + name} data-bs-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  )
}

export default Carousel;