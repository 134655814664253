export const ayudasArray = [


    {
        "pdf": 'http://hidalgo.gob.mx',
        "nombre": '2023 - 3er Trimestre',

    },

    {
        "pdf": 'http://hidalgo.gob.mx',
        "nombre": '2023 - 4to Trimestre',

    },

]