import React, { useEffect, useState } from 'react';
import {Link} from 'react-router-dom';
import './card.css';

const CardDocuments = ({array, id}) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
        setData(array)
    }, []);

    return (
        <div className="container" id={id}>
        	<div className="row">
        		{
                    data.map((item, i) => (
                        <div className="col-md-4 col-12" key={i}>
                            <div className="card-documents">
                                <h5>{item.nombre}</h5>
                                {
                                    item.anio.map((doc, n) => (
                                        <div className="card-item">
                                            <p>{doc.number}</p>
                                            <Link to={'DetalleArmonizacion/' + item.nombre}>
                                                <span className="badge bg-primary">PDF</span>
                                            </Link>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default CardDocuments;