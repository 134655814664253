import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './OtrosProgramas.css';
import { OtrosProgramasArray } from '../../json/OtrosProgramas';


class OtrosProgramas extends Component {
    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col_12">
                        <div className="otrosprogramas.title"><br></br><br></br>
                            <h1>Otros Programas </h1>
                            <hr className='hr-gob' />
                        </div>
                    </div>
                </div>

                <div className='row'>

                    {

                        OtrosProgramasArray.map((item, index) => (

                            <div key={index} className='col-md-3 col-sm-6 col-12'>
                                <div>
                                    <div className="card_container_programa">
                                        <div className='card_link'>
                                            <h5>{item.Programa}</h5>
                                            <p>{ }<span className="badge bg-secondary">

                                                <Link target="_blank" to={item.link}>Ir a la pagina</Link></span></p>
                                                <br></br><br></br>

                                        </div>
                                    </div>
                                </div>

                            </div>


                        ))}

                    
                    </div>

                </div>
            
        );
    }
}

export default OtrosProgramas