export const armonizacionArray = [
	{
		"nombre": 'Información contable',
		"anio": [
			{
				"number": '2023',
				"datos": [
					{
						"nombre": 'nombre 1',
						"pdf": 'https://transparencia.hidalgo.gob.mx',
						"xlsx": 'https://transparencia.hidalgo.gob.mx',
					},
				]
			},{
				"number": '2022',
				"datos": [
					{
						"nombre": 'nombre 1',
						"pdf": 'https://transparencia.hidalgo.gob.mx',
						"xlsx": 'https://transparencia.hidalgo.gob.mx',
					},
				]
			},{
				"number": '2021',
				"datos": [
					{
						"nombre": 'nombre 1',
						"pdf": 'https://transparencia.hidalgo.gob.mx',
						"xlsx": 'https://transparencia.hidalgo.gob.mx',
					},
				]
			},{
				"number": '2020',
				"datos": [
					{
						"nombre": 'nombre 1',
						"pdf": 'https://transparencia.hidalgo.gob.mx',
						"xlsx": 'https://transparencia.hidalgo.gob.mx',
					},
				]
			},
		]
	},{
		"nombre": 'Título V',
		"anio": [
			{
				"number": '2023',
				"datos": [
					{
						"nombre": 'nombre 1',
						"pdf": 'https://transparencia.hidalgo.gob.mx',
						"xlsx": 'https://transparencia.hidalgo.gob.mx',
					},
				]
			},{
				"number": '2022',
				"datos": [
					{
						"nombre": 'nombre 1',
						"pdf": 'https://transparencia.hidalgo.gob.mx',
						"xlsx": 'https://transparencia.hidalgo.gob.mx',
					},
				]
			},{
				"number": '2021',
				"datos": [
					{
						"nombre": 'nombre 1',
						"pdf": 'https://transparencia.hidalgo.gob.mx',
						"xlsx": 'https://transparencia.hidalgo.gob.mx',
					},
				]
			},{
				"number": '2020',
				"datos": [
					{
						"nombre": 'nombre 1',
						"pdf": 'https://transparencia.hidalgo.gob.mx',
						"xlsx": 'https://transparencia.hidalgo.gob.mx',
					},
				]
			},
		]
	},{
		"nombre": 'Bienes muebles',
		"anio": [
			{
				"number": '2023',
				"datos": [
					{
						"nombre": 'nombre 1',
						"pdf": 'https://transparencia.hidalgo.gob.mx',
						"xlsx": 'https://transparencia.hidalgo.gob.mx',
					},
				]
			},{
				"number": '2022',
				"datos": [
					{
						"nombre": 'nombre 1',
						"pdf": 'https://transparencia.hidalgo.gob.mx',
						"xlsx": 'https://transparencia.hidalgo.gob.mx',
					},
				]
			},{
				"number": '2021',
				"datos": [
					{
						"nombre": 'nombre 1',
						"pdf": 'https://transparencia.hidalgo.gob.mx',
						"xlsx": 'https://transparencia.hidalgo.gob.mx',
					},
				]
			},{
				"number": '2020',
				"datos": [
					{
						"nombre": 'nombre 1',
						"pdf": 'https://transparencia.hidalgo.gob.mx',
						"xlsx": 'https://transparencia.hidalgo.gob.mx',
					},
				]
			},
		]
	}
];